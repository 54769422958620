import React from 'react'

import { Wrapper, Details, Thumbnail } from './styles'
import ContactForm from './ContactForm'

import { Container } from 'Common'
import contact from 'Static/illustrations/601.svg'

export const Contact = () => (
  <Wrapper as={Container}>
    <Details>
      <h2 id="contact">Contact me</h2>
      <ContactForm />
    </Details>
    <Thumbnail>
      <img
        src={contact}
        alt="I’m John and I’m a Backend & Devops engineer!"
        draggable={false}
      />
    </Thumbnail>
  </Wrapper>
)
