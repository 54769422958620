/* global window, document, ga */
import React from 'react'
import { Header } from 'Theme'
import { Container } from 'Common'
import { Wrapper, IntroWrapper, Details } from './styles'

const gaOptout = e => {
	e.preventDefault()

	// Set to the same value as the web property used on the site
	let gaProperty
	try {
		gaProperty = ga.getAll().shift().b.data.values[':trackingId'] // works as of 2019-01-03
	} catch (error) {
		// do nothing right now
	}

	if (gaProperty) {
		// Disable tracking if the opt-out cookie exists.
		const disableStr = `ga-disable-${gaProperty}`
		if (document.cookie.indexOf(`${disableStr}=true`) > -1) {
			window[disableStr] = true
		}

		document.cookie = `${disableStr}=true; expires=Thu, 31 Dec 2099 23:59:59 UTC; path=/`
		window[disableStr] = true
	}
}

export const Imprint = () => (
	<Wrapper>
		<Header />
		<IntroWrapper as={Container}>
			<Details>
				<h1>Imprint & Data Privacy</h1>
				<div>
					<h2>Imprint</h2>
					<p>Stefan Natter - stefan( a t ) natter dot at - Austria</p>
				</div>
				<div>
					<h2>Credits</h2>
					<p>
            The icons/svgs used on the fronpage are created by{' '}
						<a href="https://www.freepik.com/iconicbestiary">Maxim Popov</a>.
            You can find them{' '}
						<a href="https://www.freepik.com/free-vector/two-developers-planning-their-work_1311469.htm#term=developer&page=3&position=21">
              here
						</a>
            ,{' '}
						<a href="https://www.freepik.com/free-vector/professional-programmer-engineer-writing-code_1311615.htm">
              here
						</a>{' '}
            and{' '}
						<a href="https://www.freepik.com/free-vector/partnership-agreement-deal-concept_1311511.htm">
              here
						</a>
            .
					</p>
				</div>
				<div className="page-content">
					<h2>Disclaimer</h2>
					<h3>Liability for content</h3>
					<p>
            The contents of our pages were created with great care. However, we
            can not assume any liability for the accuracy, completeness and
            timeliness of the content. As a service provider we are responsible
            for our own content on our site. However, we do not monitor
            transmitted or stored third-party information or investigate
            circumstances that indicate an illegal activity (eg. manipulation of
            our content). Upon notification of appropriate violations, we will
            remove this content immediately.
					</p>
					<h3>Liability for links</h3>
					<p>
            Our offer contains links to external websites of third parties on
            whose contents we have no influence. Therefore, we can not assume
            any liability for these external contents. The respective provider
            or operator of the pages is always responsible for the contents of
            the linked pages. The linked pages were checked for possible legal
            violations at the time of linking. Illegal content was not
            recognizable at the time of linking. However, a permanent content
            control of the linked pages is not reasonable without concrete
            evidence of an infringement. Upon notification of violations, we
            will remove such links immediately.
					</p>
					<p>
            natterstefan.me’s websites contain links to other websites. We have
            checked these links to a reasonable extent. Nevertheless,
            natterstefan.me is not responsible for the contents of and for
            ensuring data protection on the sites which we have linked. Upon
            notification of dangerous links, we will remove such links
            immediately.
					</p>
					<h2>Data privacy statement</h2>
					<p>
            The use of our website is usually possible without providing
            personal information. If we ask for personal data (for example name,
            address or email addresses) it takes place on voluntary basis. These
            data will not be disclosed to third parties without your explicit
            consent. More details are described below.
					</p>
					<p>
            Please note that data transmission over the Internet (for example,
            when communicating via e-mail) may have security vulnerabilities. A
            complete protection of the data from access by third parties is not
            possible.
					</p>
					<p>
            The use of contact data published in the context of the imprint
            obligation by third parties for sending unsolicited advertising and
            information materials is hereby expressly excluded. The operators of
            the pages expressly reserve the right to take legal action in the
            event of the unsolicited sending of advertising information, such as
            spam e-mails.
					</p>
					<h2>Which data do we collect</h2>
					<p>
            natterstefan.me analyses the use of its internet presence by means
            of the web analysis tools below (eg. Google Analytics). These tools
            are installed on the respective web servers of the provider.
					</p>
					<h2>What are cookies?</h2>
					<p>
            When you visit our website it is possible that we place information
            on your computer in the form of a cookie. Cookies are small text
            files that are sent by a web server to your browser and are saved on
            the hard disk of your computer. This information has the purpose of
            automatically recognising you the next time you visit our website
            and simplifying the navigation for you. Cookies enable us for
            instance to adapt a website to suit your interests. Naturally you
            can use our websites without the cookies. If you do not accept
            cookies, however, it is possible that this will lead to restrictions
            of certain functions when using our website.
					</p>
					<p>
            You can prevent the saving of cookies by making the appropriate
            adjustment to your browser software; we point out, however, that in
            this case you may possibly not be able to use all functions of this
            website in their full extent.
					</p>
					<h2>Cookies of natterstefan.me</h2>
					<p>
            The following cookies are set by natterstefan.me and not by a 3rd
            party tool (eg. Google Analytics):
					</p>
					<ul>
						<li>
              user-has-accepted-cookies: When you use the website you will see a
              cookie policy notice. By using our website (eg. interacting with
              it) you agree with our cookie policy. The cookie notice bar will
              stay at the top of the window as long as the user clicks on the
              "Ok" button. At this point the cookie is set.
						</li>
					</ul>
					<h2>Data that is collected by 3rd party tools</h2>
					<h3>Google Analytics</h3>
					<p>
            natterstefan.me uses Google Analytics, a web analytics service
            provided by Google, Inc. (“Google”). Google Analytics uses
            “cookies”, which are text files placed on your computer, to help the
            website analyze how users use the site. The information generated by
            the cookie about your use of the website (including your IP address)
            will be transmitted to and stored by Google on servers in the United
            States.
					</p>
					<p>
            In case of activation of the IP anonymization (which we activated),
            Google will truncate/anonymize the last octet of the IP address for
            Member States of the European Union as well as for other parties to
            the Agreement on the European Economic Area. Only in exceptional
            cases, the full IP address is sent to and shortened by Google
            servers in the USA.
					</p>
					<p>
            On behalf of the website provider Google will use this information
            for the purpose of evaluating your use of the website, compiling
            reports on website activity for website operators and providing
            other services relating to website activity and internet usage to
            the website provider. Google will not associate your IP address with
            any other data held by Google.
					</p>
					<p>
            You may refuse the use of cookies by selecting the appropriate
            settings on your browser. However, natterstefan.me points out that
            if you do this, you may not be able to use the full functionality of
            this website.
					</p>
					<p>
            Furthermore you can prevent Google’s collection and use of data
            (cookies and IP address) by downloading and installing the browser
            plug-in available under{' '}
						<a href="https://tools.google.com/dlpage/gaoptout?hl=en">
              https://tools.google.com/dlpage/gaoptout?hl=en
						</a>
            .
					</p>
					<p>
            Or you deactivate it with a DoNotTrack Cookie:{' '}
						<a href="#" onClick={gaOptout}>
              Deactive Google Analytics
						</a>
            .
					</p>
					<h2>
            How can you contact natterstefan.me regarding data protection?
					</h2>
					<p>
            The user can address questions, suggestions or requests concerning
            data processing to the following contact:
					</p>
					<p>
            Stefan Natter @Twitter:{' '}
						<a href="https://twitter.com/natterstefan">
              https://twitter.com/natterstefan
						</a>{' '}
            or via the contact form <a href="/">on the home page</a>.
					</p>
					<h2>Changes and/or alterations to the data privacy statement</h2>
					<p>
            natterstefan.me will inform the user every time changes and/or
            alterations are made to the data privacy statement on this page.
					</p>
					<p>Most recent updating of the data privacy statement: 2019-01-03.</p>
				</div>
			</Details>
		</IntroWrapper>
	</Wrapper>
)
