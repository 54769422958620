/* global window:true */
import React from 'react'

import { Wrapper, SkillsWrapper, Details, Thumbnail } from './styles'

import { Container, Button } from 'Common'
import dev from 'Static/illustrations/693.svg'

export const Skills = () => (
  <Wrapper id="about">
    <SkillsWrapper as={Container}>
      <Thumbnail>
        <img src={dev} alt="natterstefan" draggable={false} />
      </Thumbnail>
      <Details>
        <p>
          I&apos;m a Software Engineer from Austria{' '}
          <span role="img" aria-label="Austria">
            🇦🇹
          </span>{' '}
          in love with React and Node. My thoughts and posts are on{' '}
          <a href="https://medium.com/@natterstefan" className="link">
            Medium
          </a>
          ,{' '}
          <a href="https://dev.to/natterstefan" className="link">
            dev.to
          </a>{' '}
          and I tweet{' '}
          <a href="https://twitter.com/natterstefan" className="link">
            here
          </a>
          . 🐦
        </p>
        <div
          style={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
          }}
        >
          <Button
            as="a"
            href="https://medium.com/@natterstefan"
            target="_blank"
            rel="noopener noreferrer"
          >
            Follow me on Medium
          </Button>
          <Button
            as="a"
            href="https://twitter.com/natterstefan"
            target="_blank"
            rel="noopener noreferrer"
          >
            Follow me on Twitter
          </Button>
        </div>
      </Details>
    </SkillsWrapper>
  </Wrapper>
)
