import React, { useState } from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import Typist from 'react-typist'

import { Wrapper, IntroWrapper, Details, Thumbnail } from './styles'

import { Header } from 'Theme'
import { Container, Button } from 'Common'
import dev from 'Static/illustrations/569.svg'

const isBrowser = typeof window !== 'undefined'

export const Intro = () => {
  const [msgRendered, setMsgRendered] = useState(false)
  const [subMsgRendered, setSubMsgRendered] = useState(false)

  const onHeaderTyped = () => setMsgRendered(true)
  const onSubHeaderTyped = () => setSubMsgRendered(true)

  return (
    <Wrapper>
      <Header />
      <IntroWrapper as={Container}>
        <Details>
          <noscript>
            <h1>
              Hi there!{' '}
              <span role="img" aria-label="hello">
                👋🏻
              </span>
            </h1>
            <h4>
              <span>
                I&apos;m Stefan Natter, Software Engineer from Austria.
              </span>
            </h4>
            <Button as={AnchorLink} href="#contact">
              Let&apos;s get in touch
            </Button>
          </noscript>
          {isBrowser && (
            <h1>
              <Typist
                avgTypingDelay={50}
                startDelay={800}
                onTypingDone={onHeaderTyped}
                cursor={{
                  hideWhenDone: true,
                  hideWhenDoneDelay: 0,
                }}
              >
                Hi there!{' '}
                <span role="img" aria-label="hello">
                  👋🏻
                </span>
              </Typist>
            </h1>
          )}
          {isBrowser && msgRendered && (
            <h4>
              <Typist
                avgTypingDelay={50}
                startDelay={500}
                onTypingDone={onSubHeaderTyped}
                cursor={{
                  hideWhenDone: true,
                  hideWhenDoneDelay: 300,
                }}
              >
                <span>I’m Stefan Natter, Software Engineer.</span>
                <Typist.Backspace count={18} delay={800} />
                <span>Growth Hacker.</span>
                <Typist.Backspace count={14} delay={800} />
                <span>Product & Tool Tester.</span>
                <Typist.Backspace count={22} delay={800} />
                <span>Twitter Fan.</span>
              </Typist>
            </h4>
          )}
          {isBrowser && subMsgRendered && (
            <Button as={AnchorLink} href="#contact">
              Let&apos;s get in touch
            </Button>
          )}
        </Details>
        <Thumbnail>
          <img
            src={dev}
            alt="I’m Stefan Natter - Software Engineer and Growth Hacker"
            draggable={false}
          />
        </Thumbnail>
      </IntroWrapper>
    </Wrapper>
  )
}
